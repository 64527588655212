import React, { useCallback, useContext, useEffect } from 'react';
import { DataContext } from '../../data/DataProvider';
import { ENDPOINTS } from '../../services/constants';
import axios from 'axios';
import { handleInvalidSession } from '../../helpers/auth';

import './index.scss';
import MainHeader from '../../components/main-header/main-header';
import Loading from '../../components/loading/loading';
import DefaultLayout from '../../components/layouts/default-layout';
import { navigate } from 'gatsby';
import { internalApi } from '../../services/base-service';

const block = 'page-auth';
const IndexPage = ({ location }) => {
  const { setQuotation, setBrokerData, setDismissibleGlobalError } =
    useContext(DataContext);

  const getCotacao = (propostaId, token, env) => {
    return axios
      .get(
        `${process.env.GATSBY_INTERNAL_API_URL}${ENDPOINTS.proposal}/${propostaId}`,
        {
          headers: {
            Authorization: token,
            Environment: env,
          },
        }
      )
      .then((response) => response.data)
      .catch((err) => {
        if (err?.response?.status === 401 || err?.response?.status === 403)
          handleInvalidSession();
        else return err?.response?.data;
      });
  };
  const submitProposal = (proposalId, proposalData, token, env) => {
    return axios
      .post(
        `${process.env.GATSBY_INTERNAL_API_URL}${ENDPOINTS.submitProposal}/${proposalId}`,
        proposalData,
        {
          headers: {
            Authorization: token,
            Environment: env,
          },
        }
      )
      .then(() => {
        return navigate(
          `${process.env.GATSBY_URL_DIGITAL}/protector/ferramentas/propostas`
        );
      })
      .catch((err) => {
        setDismissibleGlobalError({
          ...err?.response?.data,
        });
        return navigate('/');
      });
  };

  const getCorretora = (token, env) => {
    return axios
      .get(`${process.env.GATSBY_INTERNAL_API_URL}${ENDPOINTS.corretora}`, {
        headers: {
          Authorization: token,
          Environment: env,
        },
      })
      .then((response) => response.data)
      .catch((err) => {
        if (err?.response?.status === 401 || err?.response?.status === 403)
          handleInvalidSession();
        else return err?.response?.data;
      });
  };

  /**
   * @description Checks the data coming from resquetAuth and treats the "data" before sending it to the corresponding page
   * @function sendDestination
   * @param {object} corretora - Corretora object resulting from requestAuth
   * @param {object} cotacao - Cotacao object resulting from requestAuth
   */
  const sendDestination = useCallback(
    (corretora, cotacao, operationMode, sendProposal, token, env) => {
      if (!corretora) return handleInvalidSession();
      const dadosBroker = {
        brokerId: corretora?.documento
          ? corretora?.documento.match(/\d/g).join('')
          : '',
        ...corretora,
      };

      setBrokerData({
        dadosBroker,
      });

      if (cotacao?.error) {
        setDismissibleGlobalError({
          ...cotacao,
        });
      }

      if (cotacao && !cotacao?.error && !sendProposal) {
        setQuotation({
          printMode: !!operationMode,
          ...cotacao,
        });
        return navigate('/resumo');
      }
      if (cotacao && !cotacao?.error && sendProposal) {
        return submitProposal(cotacao.quotationNumber, cotacao, token, env);
      } else {
        setQuotation(null);
        return navigate('/');
      }
    },
    [setBrokerData, setQuotation, setDismissibleGlobalError]
  );

  /**
   * @description Search for corretora and cotação information
   * @function resquetAuth
   * @param {string} urlQuotationId - Cotacao ID from URL
   */
  const requestAuth = useCallback(
    async (urlQuotationId, token, env, operationMode, sendProposal) => {
      let requestCotacao;
      const requestCorretora = getCorretora(token, env);

      if (urlQuotationId) {
        requestCotacao = getCotacao(urlQuotationId, token, env);
      }
      Promise.all([requestCorretora, requestCotacao]).then(function ([
        corretora,
        cotacao,
      ]) {
        sendDestination(
          corretora,
          cotacao,
          operationMode,
          sendProposal,
          token,
          env
        );
      });
    },
    [sendDestination]
  );

  useEffect(() => {
    setDismissibleGlobalError(null);
    const queryStringParams = location.search.split('&');

    if (!queryStringParams.length) return handleInvalidSession();

    const params = new URLSearchParams(location.search);
    const urlTokenValue = params.get('t');
    const urlQuotationId = params.get('quotationId');
    const operationMode = params.get('operationMode');
    const sendProposal = params.get('sendProposal');

    let access_token = localStorage.getItem('access_token');
    let environment = localStorage.getItem('environment');
    if (urlTokenValue) {
      axios
        .get(
          `${process.env.GATSBY_INTERNAL_API_URL}/integracao/auth?auth=${urlTokenValue}`
        )
        .then((response) => {
          access_token = response.data.auth;
          environment = response.data.environment;

          if (!access_token || !localStorage) return handleInvalidSession();

          localStorage.setItem('access_token', access_token);
          localStorage.setItem('environment', environment);

          internalApi.defaults.headers['Authorization'] = `${access_token}`;
          internalApi.defaults.headers['Environment'] = `${environment}`;

          requestAuth(
            urlQuotationId,
            access_token,
            environment,
            operationMode,
            sendProposal
          );
        })
        .catch((error) => {
          handleInvalidSession();
        });
    } else {
      requestAuth(urlQuotationId, access_token, environment);
    }
  }, [location, requestAuth, setDismissibleGlobalError]);

  return (
    <DefaultLayout>
      <main className={block}>
        <MainHeader />
        <div className="loading-fixed">
          <Loading />
        </div>
      </main>
    </DefaultLayout>
  );
};

export default IndexPage;
