import React from 'react';
import './loading.scss';
import cn from 'classnames';
import PropTypes from 'prop-types';

const block = 'loading';
const sizes = ['large', 'small'];

const Loading = ({ size, className }) => {
  return (
    <div className={cn(block, className, `${block}--${size}`)}>
      <div className="container">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

Loading.propTypes = {
  size: PropTypes.oneOf(sizes).isRequired,
  className: PropTypes.string,
};

Loading.defaultProps = {
  size: sizes[0],
};

export default Loading;
